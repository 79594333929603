import { getCustSales, exportAllInfoList, getCustSalesChart, findPurchaseReport } from "./api.js";
import Util from "@/utils/utils";
import PurchaseTable from "./components/PurchaseTable";
import { blueSymbol, redSymbol } from "../components/images";
import { queryOrg } from "@/utils/api";
import { getMatkl } from "@/views/channelWholesalePrice/businessChannelPriceadd/api";
import moment from "moment";

export default {
  components: {
    PurchaseTable
  },
  data() {
    return {
      expLoading: false,
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/reports/purchase",
          name: "purchase",
          title: "采购报表"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      count: {
        totalOccQty: 0,
        totalActQty: 0,
        totalAvaQty: 0
      },
      data: [],
      terms: {},
      showLoading: false,
      chartData: {
        dates: [],
        amount: [],
        num: []
      },
      tableData: [],
      currentPreviousDay:'',
      columns:[
        {
          label: "序号",
          prop: "xuhao",
          align:'center',
          width: 120
        },
        {
          label: "供应商",
          prop: "branchCompanyName",
          align:'left',
          width: 250
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          align:'left',
          width: 250
        },
        {
          label: "时间",
          prop: "queryDate",
          align:'left',
          width: 250
        },
        {
          label: "采购金额（元）",
          prop: "purchaseAmount",
          align:'left',
          width: 250

        },
        {
          label: "环比",
          prop: "chain",
          align:'left',
          width: 250
        },
        {
          label: "常规采购占比",
          prop: "regularPurchaseRatio",
          align:'left',
          width: 250
        },
        {
          label: "应急采购占比 ",
          prop: "emergencyPurchaseRatio",
          align:'left',
          width: 250
        },

        {
          label: "采购数量（台）",
          prop: "purchaseNum",
          align:'left',
          width: 250

        },

      ],
      //接口数据
      tableInfor:{
        purchaseTotalAmount:0,
        purchaseTotalNum:0,
        regularPurchaseRatio:0,
        emergencyPurchaseRatio:0
      },
      supplierList: [],
      MatklList: [],
      hideshow: false,
      mode2: ['month', 'month'],
      time:[],
      startDate: moment(new Date()),
      endDate: moment(new Date()),
      endNum:'0',
      startNum:'',
      filterFrom:{
        orgId: undefined,
        materialGroupId: undefined,
        queryStartTime:'',
        queryEndTime: '',
      },
      dynamicMessage: { // 动态获取提示信息汇总
        pickUpAmount: '', // 提货额
        pickUpQuantity: '', // 提货量
        chainRatio: '', // 环比
      },
    };
  },
  created() {
    this.filterFrom.queryStartTime = this.startDate.format('YYYY-MM')
    this.filterFrom.queryEndTime = this.endDate.format('YYYY-MM')
    this.endNum  =  this.filterFrom.queryEndTime.split("-").join("");
    this.startNum  =  this.filterFrom.queryStartTime.split("-").join("");
    this.getData();
  },
  mounted() {
    this.getYesterday()
    this.getSupplierList()
    this.getMetarialGroup()
    this.getMessage()
  },
  methods: {
    // 获取动态提示
    getMessage() {
      const pickUpAmount =  this.$configTotal({
        route: this.$route.fullPath,
        id:'14922074279'
      })
      const pickUpQuantity =  this.$configTotal({
        route: this.$route.fullPath,
        id:'14922074281'
      })

      this.dynamicMessage = { // 动态获取提示信息汇总
        ...this.dynamicMessage,
        pickUpAmount: pickUpAmount ? pickUpAmount.msgDesc : '', // 提货额
        pickUpQuantity: pickUpQuantity ? pickUpQuantity.msgDesc : '', // 提货量
      }
    },
    //获取最近半年月份
    getLastSixMon() {
      var data = new Date();
      //获取年
      var year = data.getFullYear();
      //获取月
      var mon = data.getMonth() + 1;
      var arry = new Array();
      for (var i = 0; i < 6; i++) {
        if (mon <= 0) {
          year = year - 1;
          mon = mon + 12;
        }
        if (mon < 10) {
          mon = "0" + mon;
        }
        arry[i] = year + "-" + mon;
        mon = mon - 1;
      }
      return arry;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChangesupplier() {

    },
    handleChangeMatklList() {
      this.getSupplierList()

    },
    //禁用日期
    disabledStartDate(startValue) {
      const endValue = this.endDate;

      if (!startValue || !endValue) {
        return false;
      }
      return (
        new Date(
          this.$util.dateFmt(new Date(startValue.valueOf()), "yyyy-MM")
        ).valueOf() >
        new Date(
          this.$util.dateFmt(new Date(endValue.valueOf()), "yyyy-MM")
        ).valueOf()
      );
    },
    disabledEndDate(endValue) {
      const startValue = this.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return (
        new Date(
          this.$util.dateFmt(new Date(startValue.valueOf()), "yyyy-MM")
        ).valueOf() >
        new Date(
          this.$util.dateFmt(new Date(endValue.valueOf()), "yyyy-MM")
        ).valueOf()
      );
    },
    //筛选日期选择变化
    dateOnStartChange(arg, arg2) {
      this.filterFrom.queryStartTime = arg2,
      this.startNum  = arg2.split("-").join("");
      if(this.filterFrom.queryEndTime && this.filterFrom.queryStartTime ) {
        if (moment(this.endDate).subtract(12, "months").toDate() > moment(this.startDate).toDate()) {
          this.$message.warning('时间范围不能超过12个月');
          this.filterFrom.queryStartTime = ''
          this.startDate = ''
          return;
        }
      }
    },
    dateOnEndChange(arg, arg2) {
      this.filterFrom.queryEndTime = arg2
      this.endNum  = arg2.split("-").join("");
      if(this.filterFrom.queryEndTime && this.filterFrom.queryStartTime ) {
        if (moment(this.endDate).subtract(12, "months").toDate() > moment(this.startDate).toDate()) {
          this.$message.warning('时间范围不能超过12个月');
          this.filterFrom.queryendTime = ''
          this.endDate = ''
          return;
        }
      }
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },
    // 获取物料组
    getMetarialGroup(id) {
      let data = {
        orgId :''
      }
      getMatkl(data).then(res=>{
        this.MatklList = res.data.list
      })
    },
    // 获取供应商
    getSupplierList() {
      queryOrg({type:2,materialGroupId:this.filterFrom.materialGroupId?this.filterFrom.materialGroupId:''}).then(res => {
        this.supplierList = res.data.orgList
      })
    },
    getYesterday() {
      let day1 = new Date();
      day1.setTime(day1.getTime()-24*60*60*1000);
      this.currentPreviousDay = day1.getFullYear()+"/" + (day1.getMonth()+1) + "/" + day1.getDate();
    },
    // 修改后的echarts
    initChar(){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(this.$refs.lineChart2);
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(38,38,38,0.69)",
          formatter: '<span style="margin-bottom:5px;font-size: 14px">{b}</span><br/>' +
            '<span style="display:inline-block;margin-right:5px;width:8px;height:8px;border-radius: 50%;' +
            'background: #F36556;"></span><span style="font-size: 12px">{a1}</span>: <span style="margin-left: 40px;font-size: 12px">{c1}</span>' +
            '<br/><span style="display:inline-block;margin-right:5px;width:8px;height:8px;border-radius: 50%;' +
            'background: #1890FF;"></span><span style="font-size: 12px">{a0}</span>: <span style="margin-left: 50px;font-size: 12px">{c0}</span> ' +
            '<br/>',
          extraCssText: "min-width:140px!important;text-align: left;",
          borderWidth: 0,
          textStyle: {
            color: "#FFFFFF",
            fontWeight: 400,
            lineHeight: 20
          }
        },
        legend: {
          data: ['金额(万元)', '数量(台)' ],
          // right: 24,
          selectedMode: false,
          icon: "rect",
          itemWidth: 8,
          itemHeight: 8
        },
        grid: {
          // left: 100
          left: "5%",
          right: "5%",
          bottom: "8%",
          top: "16%",
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartData.dates,
            splitLine: {//不显示分割线
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#c7c4c4', // 颜色
              }
            },
            axisLabel: {
              color: '#262626', // 颜色
            },
          },

        ],
        yAxis: [
          {
            type: 'value',
            name: '金额',
            min: 0,
            position: 'left',
            axisLabel: {
              formatter: '{value} '
            }
          },
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'right',
            axisLabel: {
              formatter: '{value} '
            },
            splitLine:false
          },],
        series: [
          {
            name:'数量(台)',
            type:'bar',
            barWidth : 20,//柱图宽度
            yAxisIndex: 1,

            /*设置柱状图颜色*/
            itemStyle: {
              color:'rgb(224,183,61)',
              normal: {
                barBorderRadius: [5,5,0,0],
                color:this.$echarts.graphic.LinearGradient(0,1,0,0,[
                  {
                    offset:0,
                    color:'#1890FF'
                  },

                  {
                    offset:1,
                    color:'#1890FF'
                  }
                ])
              }
            },
            data:this.chartData.num,

          },
          {
            name:'金额(万元)',
            type:'line',
            showSymbol: false,
            smooth: true,
            symbol: redSymbol,
            symbolSize: 8,
            itemStyle : {  /*设置折线颜色*/
              shadowColor: "rgba(255,167,103,0.1)",
              shadowBlur: 1,
              shadowOffsetY: 4,
              // color:'rgba(91, 245, 204, 1)'
              // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [{
                  offset: 0, color: '#FD6A6E' // 0% 处的颜色
                }, {
                  offset: 1, color: '#FD6A6E' // 100% 处的颜色
                }],
                globalCoord: false // 缺省为 false
              }
            },
            data: this.chartData.amount,
          }
        ]
      });
      //建议加上以下这一行代码，不加，当浏览器窗口缩小时，echarts显示不全。
      window.addEventListener('resize', function() {
        myChart.resize()
      });
    },
    searchClick() {
      if(!this.filterFrom.queryStartTime || !this.filterFrom.queryEndTime ) {
        this.$message.warning('日期选择不完整，请重新选择')
        return
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    reset() {
      this.filterFrom={
          orgId: undefined,
          materialGroupId: undefined,
      }
      this.startDate =  moment(new Date())
      this.endDate =  moment(new Date())
      this.filterFrom.queryStartTime =  moment(new Date()).format('YYYY-MM')
      this.filterFrom.queryEndTime =  moment(new Date()).format('YYYY-MM')
      this.pager.pageNo = 1;
      this.getData();
      this.getSupplierList()
    },
    exportFile() {
      this.expLoading = true;
      this.filterFrom.orgId =  this.filterFrom.orgId ? this.filterFrom.orgId : ''
      this.filterFrom.materialGroupId =  this.filterFrom.materialGroupId ? this.filterFrom.materialGroupId : ''
      exportAllInfoList({ ...this.filterFrom })
        .then(res => {
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "采购报表.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .catch(err => {
          this.expLoading = false;
        });
    },
    getData() {
      this.showLoading = true;
      let data = {
        ...this.filterFrom,
        orgId: this.filterFrom.orgId?  this.filterFrom.orgId  :'',
        materialGroupId: this.filterFrom.materialGroupId ? this.filterFrom.materialGroupId : '',
       }
      findPurchaseReport(data).then(res => {
        if(res.data.code == 0) {
          // 采购总额（元）
          this.tableInfor.purchaseTotalAmount = res.data.data.purchaseTotalAmount
          // 采购总量（台）
          this.tableInfor.purchaseTotalNum = res.data.data.purchaseTotalNum
          // 常规采购占比
          this.tableInfor.regularPurchaseRatio = res.data.data.regularPurchaseRatio
          // 应急采购占比
          this.tableInfor.emergencyPurchaseRatio	 = res.data.data.emergencyPurchaseRatio

          let list = res.data.data.categoryPurchaseInfoCategoryDTOList || [];

          const start = (this.pager.pageNo - 1) * this.pager.pageSize;
          this.pager.count = list.length;
          this.tableData = list.slice(start, start + this.pager.pageSize);
          // this.tableData  = res.data.data.categoryPurchaseInfoCategoryDTOList
          if (this.tableData && this.tableData.length > 0) {
            this.tableData.forEach((item, index) => {
              item.xuhao = index + 1;
              // item.proportion = (item.proportion)+'%'
            });
          }
          // let data = res.data.data.purchaseInfoList || [];
          // 如果数组数据长度大于6 截取最后六位
          let data =
            res.data.data.purchaseInfoList && res.data.data.purchaseInfoList.length > 6 ? res.data.data.purchaseInfoList.slice(-6)
              : res.data.data.purchaseInfoList && res.data.data.purchaseInfoList.length > 0 ? res.data.data.purchaseInfoList  : []
          const dates = data.map(item => {
            return (
              item.month &&
              item.month.slice(2, 4) + "." + item.month.slice(4, 6)
            );
          });
          const num = data.map(obj => {
            return obj.purchaseNum;
          });
          const amount = data.map(obj => {
            return obj.purchaseAmount
            // return (obj.purchaseAmount / 10000).toFixed(2);
          });

          this.chartData = {
            dates,
            amount,
            num
          };
          this.initChar()
          if(!res.data.data.purchaseInfoList){
            this.chartData = {
              dates:[0,0,0,0,0,0],
              amount:[0,0,0,0,0,0],
              num:[0,0,0,0,0,0],
            };
            this.initChar()
            return
          }
        } else {
          this.$message.warning(res.data.msg)
          this.chartData = {
            dates:[0,0,0,0,0,0],
            amount:[0,0,0,0,0,0],
            num:[0,0,0,0,0,0],
          };
          this.initChar()
        }
      });
    }
  }
};
