import dmshttp from "@/utils/dmsrequest";
import http from "@/utils/request";

//采购报表列表
export function getCustSales(params) {
    return http({
        method: "get",
        url: "/report/salesReportList.nd",
        params
    });
}
//采购报表列表
export function getCustSalesChart(params) {
    return http({
        method: "get",
        url: "/report/custSales.nd",
        params
    });
}
//合并数据导出
export function exportAllInfoList(data) {
    return http({
        method: "post",
        url: "/purchaseStatement/exportPurchaseReport.nd",
        responseType: 'blob',
        data
    });
}
//我的库存列表
export function getStockold(data) {
    return dmshttp({
        url: "findInventoryList",
        data
    });
}

// 获取报表数据
export function findPurchaseReport(params) {
    return http({
        method: "get",
        url: "/purchaseStatement/findPurchaseReport.nd",
        // contentType: "application/json;charset=UTF-8",
        // type: 'stringfy',
        params
    });
}